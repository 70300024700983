<template>
  <div>
    <modalConfirm
      :showDialog="modalConfirmIsOpen"
      @answerYes="removeUrl"
      @answerNo="modalConfirmIsOpen = false"
    ></modalConfirm>
    <ModalUrl
      :showSelectButton="false"
      v-if="modalUrlOpen"
      @onUpdate="itemUpdate"
      :isOpen="modalUrlOpen"
      :item="current_url"
      @onClose="modalUrlOpen = false"
    ></ModalUrl>

    <v-row class="mb-2">
      <v-col cols="3" class="font-weight-bold d-flex align-center">
        <v-btn
          icon
          class="title"
          small
          :class="`color-${icon}`"
          :href="current_url.Url"
          target="_blank"
        >
          <font-awesome-icon
            :icon="{ prefix: icon === 'globe' ? 'fa' : 'fab', iconName: icon }"
            class="mr-2"
            v-if="icon"
          />
        </v-btn>
        <a :href="current_url.Url" target="_blank">{{
          readOnly ? getNomURL : label
        }}</a>
      </v-col>
      <v-col cols="6" class="d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="writeClipboard(current_url.Url)"
              >{{ current_url.Url.substr(0, 30)
              }}{{ current_url.Url.length > 30 ? "..." : "" }}</span
            >
          </template>
          <span>Cliquez pour copier dans le presse-papier</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="3"
        v-if="!readOnly"
        class="d-flex justify-space-between align-center"
      >
        <div>
          <v-btn @click="modalUrlOpen = true" icon color="primary">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn @click="confirmDelete" icon color="error">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { slugify } from "@/plugins/utils";
import ModalUrl from "@/components/utils/modals/url.vue";
import modalConfirm from "@/components/utils/modals/yes-no.vue";
import { mapActions } from "vuex";
export default {
  components: { ModalUrl, modalConfirm },

  data: () => ({
    modalUrlOpen: false,
    modalConfirmIsOpen: false,
  }),

  created() {
    //Object.freeze(this.data)
    this.current_url = this.data;
  },

  methods: {
    ...mapActions("structure", ["deleteUrl"]),

    itemUpdate(item) {
      this.current_url = item;
      this.modalUrlOpen = false;
    },

    confirmDelete() {
      this.modalConfirmIsOpen = true;
    },
    writeClipboard(url) {
      var self = this;
      let data = new DataTransfer();

      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.$toast.success("Lien copié dans le presse-papier !", {
            timeout: 5000,
          });
        })
        .catch((e) => {
          this.$toast.error("Oups, une erreur est survenue.", {
            timeout: 5000,
          });
        });
    },
    async removeUrl() {
      try {
        await this.deleteUrl({ ID_Url: this.current_url.id });
        this.$emit("onDelete", this.current_url);
        this.modalUrlOpen = false;
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    label() {
      return this.current_url.LIST_UrlType.NomTypeUrl;
    },
    getNomURL() {
      // return this.current_url.NomUrl
      return this.current_url.LIST_UrlType.NomTypeUrl;
    },
    getRules() {
      return slugify(this.current_url.LIST_UrlType.NomTypeUrl);
    },
  },

  props: {
    data: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
  },
};
</script>
