<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="close()"
    max-width="600px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>URL</v-tab>
    </v-tabs>
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat>
          <ValidateForm
            @validated="submit"
            :loading="loading"
            style="width: 100%;"
            :showFieldObl="mandatory"
          >
            <v-col cols="12">
              <InputSelect
                label="Type d'URL"
                :items="items"
                v-model="url_type"
                item_text="NomTypeUrl"
                item_value="id"
                @onChange="typeChanged"
              ></InputSelect>

              <!-- <InputText
                v-model="current_item.NomUrl"
                label="Nom de l'url"
                rules="required|min:6"
                :disabled="!url_type"
              ></InputText> -->

              <InputText
                v-model="current_item.Url"
                label="URL"
                rules="required|min:6"
                type="url"
                :disabled="!url_type"
              ></InputText>
            </v-col>

            <v-card-actions class="px-10 pb-4">
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :disabled="!current_item.Url || !current_item.NomUrl"
                :loading="loading"
                small
                rounded
                color="blue-dark text-white font-weight-bold"
                >{{ button_text }}</v-btn
              >
              <v-btn
                @click="selectUrl"
                :disabled="!current_item.id"
                small
                rounded
                color="blue-dark text-white font-weight-bold"
                v-if="showSelectButton"
                >Sélectionner</v-btn
              >
            </v-card-actions>
          </ValidateForm>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import ValidateForm from "@/components/utils/form.vue";
import InputSelect from "@/components/utils/select.vue";
import InputText from "@/components/utils/input.vue";
export default {
  components: { ValidateForm, InputSelect, InputText },
  data: () => ({
    url_type: "",
    current_item: {},
    items: [],
    loading: false,
    hasToConfirm: false,
    mounted: false,
    mandatory: false,
  }),

  async created() {
    this.items = await this.getListTypeReseauxSociaux();
    this.setData();

    this.mounted = true;
  },

  methods: {
    ...mapActions("structure", ["updateStructureUrl", "insertStructureUrl"]),
    ...mapActions("basics", ["getListTypeReseauxSociaux"]),

    setData() {
      if (this.item) {
        this.current_item = { ...this.item };
        if (this.item.LIST_UrlType) {
          this.url_type = this.items.find((p) => p.id === this.item.ID_TypeUrl);
        }
      }
    },

    canBeSelected() {
      // TODO
      return (
        this.current_item.id ||
        this.current_item.Url ||
        this.current_item.NomUrl
      );
    },

    close() {
      this.$emit("onClose");
    },

    getTypeValue(item) {
      return item;
    },

    typeChanged(type) {
      let newItem = this.currentStructure.DOC_Url.find(
        (structure) => structure.ID_TypeUrl == type.id
      );
      if (newItem) this.current_item = { ...newItem };
      else {
        let urlName = this.items.find((item) => item.id === type);
        this.current_item = {};
        this.url_type = type;
        this.current_item.NomUrl = urlName.NomTypeUrl;
      }
      console.log("newItem:", newItem);
    },

    async submit() {
      this.hasToConfirm = false;
      this.loading = true;
      try {
        if (this.current_item.id) {
          // console.log("UPDATE");
          await this.updateStructureUrl(this.current_item);
          this.$emit("onUpdate", this.current_item);
        } else {
          // console.log("INSERT");

          this.current_item.type_id = this.url_type;
          // console.log(this.current_item);
          let response = await this.insertStructureUrl(this.current_item);
          this.current_item = response;
          this.$emit("onInsert", this.current_item);
        }

        success_notification("URL enregistrée");
        this.loading = false;
      } catch (e) {
        error_notification("Oups une erreur est survenue...");
        this.loading = false;
      }
    },

    selectUrl() {},
  },

  computed: {
    ...mapGetters("structure", ["currentStructure"]),

    getUrlRules() {
      return "required|site_web";
    },

    button_text() {
      if (this.current_item.id) {
        return "Mettre à jour";
      }
      return "Ajouter";
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    label: {},
    showSelectButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.not-editable {
  font-size: 0.8rem;
  line-height: 30px;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>
