<template>
  <div class="w-100">
    <v-row justify="center">
      <v-col cols="12" xl="10" class="gestion-du-club__form">
        <div class="font-weight-bold pb-4 text-center">
          Vous pouvez rajouter les réseaux sociaux de votre
          {{ structureTypeName }}.<br />
          Pour ce faire, remplissez les champs ci-après.
        </div>

        <div v-if="!loading">
          <InputReseauxSocial
            v-for="item in reseaux_sociaux"
            :data="item"
            :key="item.id"
            :loading="loading"
            :icon="item.LIST_UrlType.IMG_CheminLogo"
            @onDelete="onDelete"
          ></InputReseauxSocial>

          <div class="text-right">
            <v-btn
              :disabled="!hasCodeFederal"
              @click.prevent="modalUrlOpen = true"
              class="btn btn-primary mt-5"
              >Ajouter</v-btn
            >
          </div>

          <ModalUrl
            :showSelectButton="false"
            :isOpen="modalUrlOpen"
            @onInsert="modalUrlOpen = false"
            :item="{}"
            @onClose="modalUrlOpen = false"
          ></ModalUrl>
        </div>
        <div v-else class="text-center">Chargement ...</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputReseauxSocial from "@/components/GestionDeLaStructure/ReseauxSociaux/ReseauxInput.vue";
import ModalUrl from "@/components/utils/modals/url.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  name: "ReseauxSociaux",

  components: {
    InputReseauxSocial,
    ModalUrl,
  },

  async created() {
    this.reseaux_type = await this.getListTypeReseauxSociaux();
    // this.reseaux_sociaux = await this.getReseauxUrl();
    this.setData();
    this.loading = false;
  },

  data: () => ({
    loading: false,
    reseaux_type: [],
    reseaux_sociaux: [],
    modalUrlOpen: false,
  }),

  methods: {
    ...mapActions("structure", ["getReseauxUrl", "getReseauxUrlFromStrID"]),
    ...mapActions("basics", ["getListTypeReseauxSociaux"]),

    onDelete(item) {
      // console.log(item);
      let index = this.reseaux_sociaux.indexOf(item);
      if (index > -1) {
        this.reseaux_sociaux.splice(index, 1);
        console.log("this.reseaux_sociaux:", this.reseaux_sociaux);
        success_notification("URL supprimée");
      } else {
        error_notification("Ouos, une erreur est survenue...");
      }
    },

    async setData() {
      const structureID = parseInt(this.$route.params.id);
      if (structureID) {
        console.log("this.structureID *********", structureID);
        this.reseaux_sociaux = await this.getReseauxUrlFromStrID(structureID);
      } else {
        if (this.getStructure(this.currentStructureId)) {
          console.log(
            "this.currentStructureId *********",
            this.currentStructureId
          );
          this.structure = this.getStructure(
            this.currentStructureId
          ).STR_Structure;
          this.getReseauxUrlFromStrID(this.structure.id)
            .then((data) => {
              this.reseaux_sociaux = data;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.structure = null;
        }
      }
    },
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructureType",
      "hasCodeFederal",
      "currentStructureId",
      "getStructure",
    ]),

    structureTypeName() {
      let type = this.currentStructureType.SlugTypeStructure;
      if (type == "ligue") {
        return "ligue";
      } else if (type == "comite_territorial") {
        return " CT";
      } else if (type == "club_association") {
        return " club";
      } else {
        return "structure";
      }
    },
  },
};
</script>
